<template>
  <figure class="flex items-center gap-4">
    <app-image
      alt="Robin Monchauzou"
      class="w-[55px] rounded-full"
      :height="200"
      src="/images/contact_profile_picture.jpg"
      :width="200"
    />

    <figcaption>
      <p class="mb-1 font-semibold">Robin Monchauzou</p>
      <ul class="flex gap-6 text-sm">
        <li class="flex items-center gap-1">
          <app-icon icon="ph:envelope-simple" :size="18" />
          <app-text-copy-button copy-icon-on-hover text="robin@heypollen.com"
            >robin@heypollen.com</app-text-copy-button
          >
        </li>
        <li class="flex items-center gap-1">
          <app-icon icon="ph:phone" :size="18" />
          <app-text-copy-button copy-icon-on-hover text="+33678169130"
            >+33678169130</app-text-copy-button
          >
        </li>
      </ul>
    </figcaption>
  </figure>
</template>
